import {AfterViewInit, Component, Input,} from "@angular/core";
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from "src/app/common/constant";
import { ConfigWeightAssessmentItemPopupComponent } from "../../../financial-plan/modules/normal-plan/config-weight-assessment-item-popup/config-weight-assessment-item-popup.component";
import { ViewReferencePointPopupComponent } from "../../../financial-plan/modules/normal-plan/view-reference-point-popup/view-reference-point-popup.component";
import { ConfigAnnualGrowthPopupComponent } from "../../../financial-plan/modules/comprehensive-plan/config-annual-growth-popup/config-annual-growth-popup.component";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'setting-plan-icon',
  templateUrl: "./setting-plan-icon.component.html",
  styleUrls: ["./setting-plan-icon.component.scss"]
})

export class SettingPlanIconComponent {
  public Constant = Constant;
  isSetting: boolean = false;
  constructor(
      public activeModal: NgbActiveModal,
      private modalService: NgbModal,
  ) {}

  openSettingWeightPlan() {
    const options: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    options.centered = true;
    options.windowClass = "config-weight-assessment-item-modal";
    const modalRef = this.modalService.open(ConfigWeightAssessmentItemPopupComponent, options);
  }
  openSettingRatingScale() {
    const options: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    options.centered = true;
    options.windowClass = "view-reference-point-modal";
    const modalRef = this.modalService.open(ViewReferencePointPopupComponent, options);
  }
  openSettingGrowthAnnual() {
    const options: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    options.centered = true;
    options.windowClass = "config-annual-growth-modal";
    const modalRef = this.modalService.open(ConfigAnnualGrowthPopupComponent, options);
  }
}
