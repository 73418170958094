import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModalModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "src/app/common/share.module";
import { ConfigAnnualGrowthPopupComponent } from "../financial-plan/modules/comprehensive-plan/config-annual-growth-popup/config-annual-growth-popup.component";
import { TabConfigAssetsAnnualGrowthComponent } from "../financial-plan/modules/comprehensive-plan/config-annual-growth-popup/tab-config-asset-annual-growth/tab-config-asset-annual-growth.component";
import { TabConfigInflationarysAnnualGrowthComponent } from "../financial-plan/modules/comprehensive-plan/config-annual-growth-popup/tab-config-inflationary-annual-growth/tab-config-inflationary-annual-growth.component";
import { TabConfigIncomesAnnualGrowthComponent } from "../financial-plan/modules/comprehensive-plan/config-annual-growth-popup/tab-config-income-annual-growth/tab-config-income-annual-growth.component";
import { InputValueStartEndComponent } from "../financial-plan/modules/share-plan/components/input-value-start-end/input-value-start-end.component";
import { FormRowStartEndComponent } from "../financial-plan/modules/share-plan/components/input-value-start-end/form-row-start-end/form-row-start-end.component";
import { ConfigWeightAssessmentItemPopupComponent } from "../financial-plan/modules/normal-plan/config-weight-assessment-item-popup/config-weight-assessment-item-popup.component";
import { ViewReferencePointPopupComponent } from "../financial-plan/modules/normal-plan/view-reference-point-popup/view-reference-point-popup.component";
import { SettingPlanIconComponent } from "./components/setting-plan-icon/setting-plan-icon.component";

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
  ],
  declarations: [
    ConfigAnnualGrowthPopupComponent,
    TabConfigAssetsAnnualGrowthComponent, 
    TabConfigInflationarysAnnualGrowthComponent, 
    TabConfigIncomesAnnualGrowthComponent,
    InputValueStartEndComponent,
    FormRowStartEndComponent,
    ConfigWeightAssessmentItemPopupComponent,
    ViewReferencePointPopupComponent,
    SettingPlanIconComponent
  ],
  exports: [
    ConfigAnnualGrowthPopupComponent,
    ConfigWeightAssessmentItemPopupComponent,
    ViewReferencePointPopupComponent,
    SettingPlanIconComponent
  ],
  providers:[
  ]
})
export class PublicConfigModule {}
