<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="pointSetupLabel">
      Thang điểm đánh giá tham khảo
    </h5>
    <button type="button" class="close" (click)="dismiss()" aria-label="Close">
      <img src="./assets/images/icons/ic-close.svg" alt="" />
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body-content">
      <p>
        Fplan đề xuất thang điểm đánh giá được mô tả bên dưới.<br />
        <strong>Tư vấn viên</strong> có thể tùy chỉnh thang điểm này theo
        từng danh mục SKTC của khách hàng, dựa trên chuyên môn, nghiệp vụ và
        kinh nghiệm tư vấn của mình, giúp họ có những giải pháp hiệu quả để
        cải thiện SKTC.
      </p>
    </div>

    <div class="modal-body-content" *ngFor="let item of list">
      <div class="modal-body-status">
        <span class="point__state" [style.backgroundColor]="item.color"></span>
        <label for="">{{item.title}}</label>
      </div>
      <div [innerHTML]="item.content">

      </div>
    </div>
  </div>
</div>