import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Constant } from "src/app/common/constant";
import { InputDataPopupComponent } from "src/app/common/extend-code/input-data-popup.component";
import { EventManagerService } from "src/app/common/service/event-manager.service";
import { AppQueries } from "src/app/state";

@Component({
  selector: "view-reference-point-popup",
  templateUrl: "./view-reference-point-popup.component.html",
  styleUrls: ["./view-reference-point-popup.component.scss"],
})
export class ViewReferencePointPopupComponent extends InputDataPopupComponent implements OnInit {
  public Constant = Constant;
  list: any[] = [];
  constructor(
    public eventManager: EventManagerService,
    public activeModal: NgbActiveModal,
    private appQueries: AppQueries,
  ) {
    super(eventManager, activeModal);
  }
  ngOnInit(): void {
    this.subscriptions = [
      this.appQueries.sourceInfos$.subscribe((sourceInfos) => {
        if (!sourceInfos) {
          return;
        }
        this.list = JSON.parse(sourceInfos.healthCheckEvaluationReferences);
      }),
    ];
  }
  dismiss() {
    this.activeModal.dismiss();
  }
}
